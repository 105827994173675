import { __awaiter } from "tslib";
import { cardInforUseCase } from "@/usecases/CardInforUseCase";
import BreadCrumb from "@/components/BreadCrumb.vue";
import DetailsCurrency from "@/components/DetailsCurrency.vue";
import SideBar from "@/components/SideBar.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import { mapGetters } from "vuex";
import { MOUDLE_WEB3_NAME } from "@/store/modules/web3/constant";
export default {
    components: {
        Header,
        Footer,
        SideBar,
        DetailsCurrency,
        BreadCrumb,
    },
    data: function () {
        return {
            detailsCurr: [],
            cardInfo: new cardInforUseCase(),
            showLoading: false,
        };
    },
    mounted: function () {
        this.getPortfolio();
    },
    computed: Object.assign({}, mapGetters(MOUDLE_WEB3_NAME, ['getAddress'])),
    methods: {
        getPortfolio() {
            return __awaiter(this, void 0, void 0, function* () {
                this.showLoading = true;
                this.cardInfo.resetDataCart();
                yield this.cardInfo.getDataCurrency(this.getAddress);
                this.detailsCurr = this.cardInfo.getDataFromAPI();
            });
        }
    },
    watch: {
        detailsCurr: function () {
            if (this.detailsCurr.length !== 0) {
                this.showLoading = !this.showLoading;
            }
        },
    },
};
