import { __awaiter } from "tslib";
import Chart from "../components/Chart.vue";
import { CardMetricsUseCase } from "@/usecases/CardMetricsUseCase";
import { CardMechanicsUseCase } from "@/usecases/CardMechanicsUseCase";
import Vue from "vue";
import { PriceTokenUseCase } from "@/usecases/GetPriceTokenUsecase";
import BreadCrumb from "@/components/BreadCrumb.vue";
import SideBar from "@/components/SideBar.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CardListEvent from "@/components/CardListEvent.vue";
import { lineChart } from "@/chart-data/chart-data";
export default Vue.extend({
    components: {
        Header,
        Footer,
        SideBar,
        BreadCrumb,
        CardListEvent,
        Chart,
    },
    data() {
        return {
            lineChart: lineChart,
            value: "-",
            percent: "-",
            detailsMetricsCard: Object,
            detailsMechanicsCard: Object,
            detailPriceCard: {},
            cardInfo: new CardMetricsUseCase(),
            cardMechanics: new CardMechanicsUseCase(),
            price_info: new PriceTokenUseCase(),
        };
    },
    computed: {
        formattedValue() {
            if (isNaN(this.value)) {
                return this.value;
            }
            let valueStr = String(this.value);
            let separatorSymbol = '.';
            if (valueStr.indexOf(separatorSymbol) < 0) {
                separatorSymbol = ',';
            }
            const numberParts = valueStr.split(separatorSymbol);
            let decimalPart = numberParts[1];
            if (numberParts.length === 0 || !decimalPart) {
                return valueStr;
            }
            let shortenZeros = '';
            let i;
            for (i = 0; i < decimalPart.length; i++) {
                if (decimalPart[i] !== '0') {
                    shortenZeros = i < 3 ? Array(i).fill('0').join('') : `0<sub>${i}</sub>`;
                    break;
                }
            }
            return numberParts[0] + separatorSymbol + shortenZeros + decimalPart.slice(i, i + 4);
        }
    },
    methods: {
        getDataMetrics() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.cardInfo.getData().then(() => {
                    this.detailsMetricsCard = this.cardInfo.getDataFromAPI();
                });
            });
        },
        getDataMechanics() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.cardMechanics.getData().then(() => {
                    this.detailsMechanicsCard = this.cardMechanics.getDataFromAPI();
                });
            });
        },
        getPriceInfo() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.price_info.getData();
                this.detailPriceCard = this.price_info.getDataFromAPI();
                this.value = this.detailPriceCard.price;
            });
        },
    },
    watch: {},
    created() {
        this.getPriceInfo();
        this.getDataMetrics();
        this.getDataMechanics();
    },
});
