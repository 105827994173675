import { __awaiter } from "tslib";
import { getHisRef } from "@/api/transaction/get";
export class GetHistoryRefUseCases {
    constructor() {
        this.list = [];
    }
    getDataListHistory(offset, limit) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataEvent = yield getHisRef(offset, limit);
            if (dataEvent == undefined) {
                return;
            }
            this.list = dataEvent.data;
        });
    }
    getDataFromAPI() {
        return this.list;
    }
}
