import { __awaiter } from "tslib";
import { getNonce, performLogin, register } from "@/api/connect/connect";
import { signMessage } from "@/web3/sign";
import { checkHolderToken } from "@/api/holder/holder";
import { SET_IS_ENABLE, SET_HOLDER, SET_ID_TOKEN, SET_CONNECTED_WALLET, SET_SIGNATURE, SET_PROCESSING, CLEAN, LOGIN_ACTION, LOGOUT_ACTION, CONNECT_ACTION, SELECT_WALLET, INIT_WALLET, SET_ADDRESS, SET_PROVIDER } from "@/store/modules/web3/constant";
import { Web3Provider } from "@ethersproject/providers";
export const actions = {
    [INIT_WALLET]({ state, commit, dispatch }) {
        return __awaiter(this, void 0, void 0, function* () {
            state.web3Onboard.state.select().subscribe((update) => {
                var _a, _b;
                if (update.wallets.length <= 0) {
                    commit(CLEAN);
                }
                else {
                    const updateWalletAddress = update.wallets[0].accounts[0].address;
                    let currentWalletAddress = null;
                    // @ts-ignore
                    if (state.connectedWallet && state.connectedWallet.accounts) {
                        // @ts-ignore
                        currentWalletAddress = (_b = (_a = state.connectedWallet) === null || _a === void 0 ? void 0 : _a.accounts[0]) === null || _b === void 0 ? void 0 : _b.address;
                    }
                    commit(SET_CONNECTED_WALLET, update.wallets[0]);
                    if (updateWalletAddress !== currentWalletAddress) {
                        dispatch(SELECT_WALLET, update.wallets[0]);
                    }
                }
            });
        });
    },
    [CONNECT_ACTION]({ state }) {
        return __awaiter(this, void 0, void 0, function* () {
            yield state.web3Onboard.connectWallet();
        });
    },
    [LOGOUT_ACTION]({ state, commit }) {
        return __awaiter(this, void 0, void 0, function* () {
            localStorage.removeItem("address");
            localStorage.removeItem("signature");
            localStorage.removeItem("holder");
            localStorage.removeItem("idToken");
            localStorage.removeItem("onboard.js:last_connected_wallet");
            commit(SET_ADDRESS, "");
            commit(SET_SIGNATURE, "");
            commit(SET_HOLDER, false);
            commit(SET_ID_TOKEN, "");
            commit(SET_PROVIDER, null);
            const currentWallet = yield state.web3Onboard.state.get().wallets[0];
            yield state.web3Onboard.disconnectWallet({ label: currentWallet.label });
        });
    },
    [LOGIN_ACTION]({ commit }, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            localStorage.removeItem("token");
            localStorage.removeItem("idToken");
            localStorage.removeItem("holder");
            commit(SET_ID_TOKEN, payload.idToken);
            let holder = undefined;
            if (payload.account != null) {
                holder = yield checkHolderToken(payload.account);
            }
            //TODO: Stupid cast variable
            commit(SET_HOLDER, !!holder);
            commit(SET_IS_ENABLE, true);
            commit(SET_PROCESSING, false);
        });
    },
    [SELECT_WALLET]({ state, commit, dispatch }, wallet) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (localStorage.getItem("address") !== null) {
                commit(SET_ADDRESS, localStorage.getItem("address"));
            }
            if (localStorage.getItem("signature") !== null) {
                commit(SET_SIGNATURE, localStorage.getItem("signature"));
            }
            if (localStorage.getItem("idToken") !== null) {
                commit(SET_ID_TOKEN, localStorage.getItem("idToken"));
            }
            if (localStorage.getItem("holder") !== null) {
                commit(SET_HOLDER, JSON.parse(localStorage.getItem("holder") || ""));
            }
            if (wallet) {
                const currentAddress = (_a = wallet.accounts[0]) === null || _a === void 0 ? void 0 : _a.address;
                if (!state.signature || !state.address || state.address !== currentAddress) {
                    commit(SET_PROCESSING, true);
                    let [status_code, nonceReq] = yield getNonce(currentAddress);
                    if (status_code === 404 || nonceReq === "") {
                        const registered = yield register(currentAddress);
                        if (registered == null) {
                            return;
                        }
                        else {
                            nonceReq = registered.nonce;
                        }
                    }
                    else if (status_code !== 200) {
                        return;
                    }
                    // @ts-ignore
                    state.provider = new Web3Provider(wallet.provider);
                    const signature = yield signMessage(state.provider, nonceReq);
                    if (!signature) {
                        commit(SET_PROCESSING, false);
                        dispatch(LOGOUT_ACTION);
                        return;
                    }
                    commit(SET_ADDRESS, currentAddress);
                    commit(SET_SIGNATURE, signature);
                    let result = yield performLogin(currentAddress, signature);
                    yield dispatch(LOGIN_ACTION, {
                        account: currentAddress,
                        idToken: result.idToken
                    });
                }
                else {
                    // @ts-ignore
                    state.provider = new Web3Provider(wallet.provider);
                    commit(SET_IS_ENABLE, true);
                }
            }
        });
    }
};
