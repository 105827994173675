import isMobile from "@/helper/platform";
export function sliceContentMobile(obj) {
    if (isMobile()) {
        let temp = obj.content;
        if (temp.length > 30)
            return temp.slice(0, 30) + "...";
        return temp;
    }
    return obj.content;
}
export function sliceWalletIDMobile(text) {
    if (isMobile()) {
        let temp = text;
        return temp.slice(0, 5) + "..." + temp.slice(-5);
    }
    return text;
}
export function sliceWalletID(text) {
    let temp = text;
    return temp.slice(0, 5) + "..." + temp.slice(-5);
}
export function sliceWalletIDMobileDynanmic(text, from, to) {
    if (isMobile()) {
        let temp = text;
        return temp.slice(from, to) + "..." + temp.slice(-to);
    }
    return text;
}
