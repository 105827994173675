import { __awaiter } from "tslib";
import { postUserProfileRegister } from '../api/transaction/post';
export class EventRegUseCases {
    constructor() {
        this.data = {
            event_id: 0,
            telegram: "",
            twitter: "",
            reddit: "",
        };
    }
    getDataFromForm(form) {
        this.data = form;
    }
    postData() {
        return __awaiter(this, void 0, void 0, function* () {
            yield postUserProfileRegister(this.data);
        });
    }
}
