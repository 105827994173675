export const ResponseData = (res) => {
    if (res) {
        return res.data;
    }
    return undefined;
};
export const ResponseStatus = (res) => {
    if (res) {
        return res.data.message;
    }
    return undefined;
};
