import { __awaiter } from "tslib";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import SideBar from "../components/SideBar.vue";
import BreadCrumb from "../components/BreadCrumb.vue";
import { toastMessage } from "@/lib/toast";
import { PostRefUseCases } from "@/usecases/PostRefUseCases";
import { GetCheckRefUseCases } from "@/usecases/GetCheckRefUseCases";
import { formatDateObj } from "@/helper/format-date";
import { GetHistoryRefUseCases } from "@/usecases/GetHistoryRefUseCases";
import moment from 'moment';
import isMobile from "@/helper/platform";
export default {
    components: {
        Header,
        Footer,
        SideBar,
        BreadCrumb,
    },
    data: function () {
        return {
            inputBlank: false,
            inputWalletID: {
                ref_code: ''
            },
            isDisable: false,
            showHistory: false,
            postRefUC: new PostRefUseCases(),
            getCheckRef: new GetCheckRefUseCases(),
            CheckRef: [],
            history: new GetHistoryRefUseCases(),
            listHistory: [],
            selectMonth: '',
            listMonth: ["all", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12",],
            opened: [],
            listEventName: ["all", "01", "02"],
        };
    },
    methods: {
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1);
            }
            else {
                this.opened.push(id);
            }
        },
        copyStringToClipboard(str) {
            this.showToastCopy();
            var el = document.createElement('textarea');
            el.value = str;
            el.setAttribute('readonly', '');
            el.style.cssText = 'position: absolute, left: -9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
        checkNullInput() {
            this.inputBlank = this.inputWalletID.ref_code === "";
        },
        submit: function () {
            return __awaiter(this, void 0, void 0, function* () {
                this.checkNullInput();
                if (this.inputWalletID.ref_code) {
                    this.isDisable = true;
                    this.postRefUC.getDataFromForm(this.inputWalletID);
                    yield this.postRefUC.postData()
                        .then(() => __awaiter(this, void 0, void 0, function* () {
                        yield this.getHisCheckRef().finally(() => {
                            if (this.CheckRef.event_id !== -1) {
                                this.showToastDone();
                            }
                            else {
                                this.showToastErr(toastMessage.refMySelf);
                                this.isDisable = false;
                            }
                        });
                    }))
                        .catch((error) => {
                        if (error.data.code === 4005) {
                            this.showToastErr(toastMessage.refMySelf);
                        }
                        else {
                            this.showToastErr(toastMessage.err);
                        }
                        this.isDisable = false;
                    }).finally(() => {
                        this.inputWalletID.ref_code = "";
                    });
                }
            });
        },
        getHisCheckRef() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.getCheckRef.getDataFromAPI();
                this.CheckRef = this.getCheckRef.getData();
                if (this.CheckRef.event_id !== -1) {
                    this.isDisable = true;
                    this.showHistory = true;
                    this.CheckRef.updated_at = formatDateObj(this.CheckRef.updated_at);
                }
            });
        },
        getHistory() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.history.getDataListHistory(0, 100);
                this.listHistory = this.history.getDataFromAPI();
            });
        },
        formatDateObj(list) {
            return formatDateObj(list);
        },
        showToastErr(text) {
            this.$toast.open({
                message: text,
                type: "error",
                position: "top-right",
                duration: 5000,
            });
        },
        showToastCopy() {
            this.$toast.open({
                message: toastMessage.copy,
                type: "success",
                position: "top-right",
                duration: 5000,
            });
        },
        showToastDone() {
            this.$toast.open({
                message: toastMessage.postSuccess,
                type: "success",
                position: "top-right",
                duration: 5000,
            });
        },
    },
    created() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getHistory();
            yield this.getHisCheckRef();
        });
    },
    computed: {
        resultQuery() {
            if (this.selectMonth !== "all" && this.selectMonth) {
                return this.listHistory.filter((item) => {
                    let check = moment(item.UpdatedAt).utc();
                    return this.selectMonth === check.format('MM');
                });
            }
            else {
                return this.listHistory;
            }
        },
        checkPlatform() {
            return isMobile();
        },
    },
    watch: {
        listHistory: function () {
            if (this.listHistory !== null) {
                this.listHistory.filter((e) => {
                    if (!this.listEventName.includes(e.event_name)) {
                        this.listEventName.push(e.event_name);
                    }
                });
            }
        },
    }
};
