export default {
    props: {
        data: {
            type: Object,
        }
    },
    mounted() {
    },
    methods: {
        formatNumber(number) {
            if (number == undefined) {
                return "-";
            }
            let rs = number + '.';
            return rs.replace(/(\d)(?=(\d{3})+\.)/g, '$1,').slice(0, -1);
        },
    }
};
