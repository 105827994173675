import { __awaiter } from "tslib";
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
function rgiWallet() {
    if (typeof window === 'undefined')
        return () => null;
    return () => {
        return {
            label: 'RGI Wallet',
            // @ts-ignore
            getIcon: () => __awaiter(this, void 0, void 0, function* () { return (yield import('./icon')).default; }),
            getInterface: () => __awaiter(this, void 0, void 0, function* () {
                let provider;
                if (window['roboinu']) {
                    provider = window['roboinu'];
                    return {
                        provider
                    };
                }
                else if (window['ethereum']) {
                    if (!isMobile) {
                        window.open('https://chromewebstore.google.com/detail/rgi-wallet/jhckpnnnpdipkakahjjhlpnieglnjcic?hl=en-US&utm_source=ext_sidebar', '_blank');
                        throw new Error('Please Install RGI Wallet to use this wallet');
                    }
                    provider = window['ethereum'];
                    return {
                        provider
                    };
                }
                else {
                    isMobile
                        ? window.open(`https://eclaim.roboglobal.info/links/${process.env.VUE_APP_WEB_URL}`, '_blank')
                        : window.open('https://chromewebstore.google.com/detail/rgi-wallet/jhckpnnnpdipkakahjjhlpnieglnjcic?hl=en-US&utm_source=ext_sidebar', '_blank');
                    throw new Error('Please Install RGI Wallet to use this wallet');
                }
            })
        };
    };
}
export default rgiWallet;
