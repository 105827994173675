import { __awaiter } from "tslib";
import { postRef } from "@/api/transaction/post";
export class PostRefUseCases {
    constructor() {
        this.data = {};
    }
    getDataFromForm(walletid) {
        var _a;
        this.data = walletid;
        this.data.ref_code = (_a = this.data.ref_code) === null || _a === void 0 ? void 0 : _a.toUpperCase();
    }
    postData() {
        return __awaiter(this, void 0, void 0, function* () {
            yield postRef(this.data);
        });
    }
}
