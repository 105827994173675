import { __awaiter } from "tslib";
import { getMigrateHolder } from "@/api/migrate/airdrop";
export class MigrateInfoUseCase {
    constructor() {
        this.data = {};
    }
    getData(address) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield getMigrateHolder(address);
            if (result == undefined) {
                return;
            }
            this.data.Id = 2;
            this.data.claimed = result.claimed;
            this.data.address = result.address;
            this.data.amount = result.amount;
            this.data.bonus = result.bonus;
            this.data.signature = result.signature;
        });
    }
    getDataFromAPI() {
        return this.data;
    }
}
