import { __awaiter } from "tslib";
import { listEventsUseCases } from "@/usecases/ListEventUseCases";
import isMobile from "@/helper/platform";
import { toastMessage } from "@/lib/toast";
import * as defineStatus from "@/define/event-status";
import * as defineType from "@/define/event-type";
export default {
    data() {
        return {
            searchEventsName: "",
            statusCode: ["Finished", "Opening", "Voting"],
            statusColor: [
                "badge badge-pill badge-warning",
                "badge badge-pill badge-primary",
                "badge badge-pill badge-success",
            ],
            detailsListEvent: [],
            listEvent: new listEventsUseCases(),
            isPC: true,
            isExactActive: true,
            isHighlightBanner: true,
            opened: [],
            event_detail: {
                id: 3,
                banner_home: "banner-home",
                banner_detail: "banner-detail",
                wallet: [
                    {
                        address: "",
                        quantity: ""
                    },
                ],
            }
        };
    },
    computed: {
        resultQuery() {
            if (this.searchEventsName) {
                return this.detailsListEvent.filter((item) => {
                    return this.searchEventsName
                        .toLowerCase()
                        .split(" ")
                        .every((v) => (item.event_name || "").toLowerCase().includes(v));
                });
            }
            else {
                return this.detailsListEvent;
            }
        },
        checkPlatform() {
            return isMobile();
        },
        isEventsPage() {
            return this.$route.name === "Events";
        }
    },
    methods: {
        onClickBanner() {
            this.$router.push({ name: 'Migration' });
            // this.$router.push({name: 'Air Drop', params: {eventID: this.event_detail.id}})
        },
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1);
            }
            else {
                this.opened.push(id);
            }
        },
        getListEvent() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.listEvent.getDataListEvent(0, 10);
                this.detailsListEvent = this.listEvent.getDataFromAPI();
            });
        },
        showToast() {
            this.$toast.open({
                message: toastMessage.eventFinished,
                type: "error",
                position: "top-right",
                duration: 5000,
            });
        },
        eventRouter(type, status) {
            switch (status) {
                case defineStatus.EVENT_OPEN: {
                    if (type === defineType.EVENT_POST) {
                        return "Post";
                    }
                    return "Reference Code";
                }
                case defineStatus.EVENT_VOTE:
                    return "vote";
            }
        },
    },
    created() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getListEvent();
        });
    },
    updated() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    },
    mounted() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    },
    watch: {},
};
