import { __awaiter } from "tslib";
import axios from "axios";
import { ResponseData } from "@/api/transaction/trans";
const BASE_URL = "https://api.roboglobal.info/v1/price";
export function getPriceToken() {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.get(BASE_URL + "/public/token", {
            params: {
                token: "0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b",
                tokenbase: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
                network: "ethereum"
            },
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(ResponseData);
    });
}
