import { __awaiter } from "tslib";
import { getListEvent } from '@/api/transaction/get';
import { dateTimeToUTC } from "@/helper/format-date";
export class listEventsUseCases {
    constructor() {
        this.list = [];
    }
    getDataListEvent(offset, limit) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataEvent = yield getListEvent(offset, limit);
            if (dataEvent == undefined) {
                return;
            }
            this.list = dataEvent.data;
        });
    }
    formatData(list) {
        let defautTime = "0001-01-01T00:00:00Z";
        for (const index in list) {
            if (list[index].event_start === defautTime) {
                list[index].event_start = "-";
            }
            else {
                list[index].event_start = dateTimeToUTC(list[index].event_start);
            }
            if (list[index].event_end === defautTime) {
                list[index].event_end = "-";
            }
            else {
                list[index].event_end = dateTimeToUTC(list[index].event_end);
            }
        }
    }
    getDataFromAPI() {
        this.formatData(this.list);
        return this.list;
    }
}
