import { __awaiter } from "tslib";
import { GetEventRegListUseCases } from "@/usecases/GetEventRegListUseCases";
import { formatDateLowerCaseObj } from "@/helper/format-date";
export default {
    data() {
        return {
            historyReg: new GetEventRegListUseCases(),
            listHistoryReg: {},
        };
    },
    methods: {
        getHistoryReg() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.historyReg.getDataListHistory(0, 100, Number(this.$route.params.eventID));
                this.listHistoryReg = this.historyReg.getDataFromAPI();
                formatDateLowerCaseObj(this.listHistoryReg);
            });
        },
    },
    created() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getHistoryReg();
        });
    }
};
