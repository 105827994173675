export default {
    name: '',
    components: {},
    data() {
        return {
            is_open: false,
            title: "",
            description: "",
            type: ""
        };
    },
    mounted() {
        this.$eventBus.$on("showNoti", (e) => {
            this.showNoti(e);
        });
    },
    destroyed() {
        this.$eventBus.$off("showNoti");
    },
    methods: {
        showNoti(e) {
            this.title = e.title;
            this.description = e.description;
            this.type = e.type;
            this.is_open = true;
            setTimeout(() => {
                this.is_open = false;
            }, e.duration);
        }
    }
};
