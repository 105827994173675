import { __awaiter } from "tslib";
import axios from "axios";
import { ResponseData } from "@/api/transaction/trans";
const BASE_URL = "https://api.roboglobal.info/v1/token";
export function getCirculate() {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.get(BASE_URL + "/public/circulate", {
            params: {
                token: process.env.VUE_APP_MAIN_TOKEN_CONTRACT,
                network: process.env.VUE_APP_NETWORK
            },
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(ResponseData);
    });
}
