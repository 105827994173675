import { __awaiter } from "tslib";
import { postLinkReg } from "@/api/transaction/post";
export class PostLinkRegUseCases {
    constructor() {
        this.data = {};
    }
    getDataFromForm(form) {
        this.data = form;
    }
    postData() {
        return __awaiter(this, void 0, void 0, function* () {
            yield postLinkReg(this.data);
        });
    }
}
