import { __awaiter } from "tslib";
import axios from "axios";
import { ResponseData } from "@/api/transaction/trans";
import { getIdToken } from "@/api/token";
const BASE_URL = "https://api.roboglobal.info/v1/dao";
export function getVoteProposal() {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.get(BASE_URL + "/list", {
            params: {
                network: process.env.VUE_APP_NETWORK
            },
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
                'Authorization': getIdToken(),
            }
        }).then(ResponseData);
    });
}
export function getHotProposal() {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.get(BASE_URL + "/hot", {
            params: {},
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
                'Authorization': getIdToken(),
            }
        }).then(ResponseData);
    });
}
export function getAddressVoted(pId, _type) {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.get(BASE_URL + "/result", {
            params: {
                type: _type,
                network: process.env.VUE_APP_NETWORK,
                proposal_id: pId
            },
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
                'Authorization': getIdToken(),
            }
        }).then(ResponseData);
    });
}
