import { __awaiter } from "tslib";
import axios from "axios";
import { ResponseData, ResponseStatus } from "@/api/transaction/trans";
import { getIdToken } from "@/api/token";
const BASE_URL = "https://api.roboglobal.info/v1/airdrop";
export function getAirdropHolder(address, times) {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.get(BASE_URL + "/holder", {
            params: {
                address: address,
                times: times
            },
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
                'Authorization': getIdToken(),
            }
        }).then(ResponseData);
    });
}
export function updateAirdropHolder(address) {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.post(BASE_URL + "/holder", {
            address: address,
            action: 1,
        }, {
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
                'Authorization': getIdToken(),
            }
        }).then(ResponseStatus);
    });
}
