import { __awaiter } from "tslib";
import axios from "axios";
import { ResponseData } from "@/api/transaction/trans";
import { getIdToken } from "@/api/token";
const BASE_URL = "https://api.roboglobal.info/v1/migration";
export function getMigrateHolder(address) {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.get(BASE_URL + "/holder", {
            params: {
                address: address,
            },
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
                'Authorization': getIdToken(),
            }
        }).then(ResponseData);
    });
}
