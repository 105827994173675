import { __awaiter } from "tslib";
import axios from "axios";
import { ResponseData } from "@/api/transaction/trans";
import { getIdToken } from "@/api/token";
const BASE_URL = "https://api.roboglobal.info/v1/portfolio";
export function getPortfolioHolder(address) {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.post(BASE_URL + "/calc", {
            "wallet": address,
            "type": "erc20",
            "token": "0x0e6fa9c050c8a707e7f56a2b3695665e4f9eac9b",
            "network": "ethereum"
        }, {
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
                'Authorization': getIdToken(),
            }
        }).then(ResponseData);
    });
}
