import { __awaiter } from "tslib";
import { getCardMMC } from '@/api/transaction/get';
export class CardMetricsUseCase {
    constructor() {
        this.cards = {};
    }
    getData() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield getCardMMC();
            if (data == undefined) {
                return;
            }
            this.cards.dailyVolumeETH = data.data.dailyVolumeETH.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            this.cards.dailyVolumeToken = data.data.dailyVolumeToken.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            this.cards.dailyVolumeUSD = data.data.dailyVolumeUSD.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            this.cards.priceUSD = '$ ' + data.data.priceUSD.toFixed(15).toString();
        });
    }
    getDataFromAPI() {
        return this.cards;
    }
}
