import { __awaiter } from "tslib";
import nameOfCard from '@/data/nameCards';
import { getPortfolioHolder } from "@/api/portfolio/portfolio";
export class cardInforUseCase {
    constructor() {
        this.cards = [];
    }
    getDataCurrency(wallet) {
        return __awaiter(this, void 0, void 0, function* () {
            const cardData = yield getPortfolioHolder(wallet);
            if (cardData == undefined) {
                return;
            }
            let i = 0;
            for (const index in cardData) {
                this.pushDataCard({
                    title: nameOfCard[i],
                    value: cardData[index].toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                });
                i = i + 1;
            }
        });
    }
    pushDataCard(cardData) {
        this.cards.push(cardData);
    }
    resetDataCart() {
        this.cards.splice(0);
    }
    getDataFromAPI() {
        return this.cards;
    }
}
