import { __awaiter } from "tslib";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import Proposal from "@/components/vote/Proposal.vue";
import VotingABI from '@/abi/Voting.json';
import isMobile from "@/helper/platform";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { EventState, OptionResultChooseVote, OptionResultType, OptionResultYesNoVote } from "@/entities/ProposalDetail";
import { mapGetters } from "vuex";
import { MOUDLE_WEB3_NAME } from "@/store/modules/web3/constant";
import { ethers } from "ethers";
import { VoteInfoUseCase } from "@/usecases/GetVoteInfoUseCase";
import { getCirculate } from "@/api/holder/circulate";
export default {
    components: {
        Proposal,
        Header,
        Footer,
        SideBar,
        BreadCrumb,
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            isMobile,
            swiperOption: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            isFetch: true,
            searchEventsName: "",
            list_stage: ["All", "Open", "Expired"],
            hotProposal: 0,
            list_event: (Array),
            current_stage_search: 0,
            isExactActive: true,
            vote_info: new VoteInfoUseCase(),
            symbol: "RBIF",
            totalSupply: ethers.utils.parseEther("1000000"),
            contract: null,
            contractAddr: process.env.VUE_APP_DAO_CONTRACT,
        };
    },
    methods: {
        getOption1(event) {
            if (event.OptionsVote == OptionResultType.YesNoQuestion) {
                return OptionResultYesNoVote.InFavor;
            }
            return OptionResultChooseVote.Option1;
        },
        getOption2(event) {
            if (event.OptionsVote == OptionResultType.YesNoQuestion) {
                return OptionResultYesNoVote.Against;
            }
            return OptionResultChooseVote.Option2;
        },
        createContractObj() {
            return __awaiter(this, void 0, void 0, function* () {
                const signer = this.getProvider.getSigner();
                this.contract = new ethers.Contract(this.contractAddr, VotingABI, signer);
            });
        },
        getHotProposal: function () {
            return this.list_event[this.hotProposal];
        },
        getEventStageProposal: function (id) {
            let eventStage = EventState.None;
            try {
                eventStage = this.list_event[id].event_stage;
            }
            catch (e) {
                eventStage = EventState.None;
            }
            return eventStage;
        },
        getProposalList: function () {
            return __awaiter(this, void 0, void 0, function* () {
                // await this.vote_info.GetMockData();
                yield this.vote_info.getData().then(() => {
                    this.list_event = this.vote_info.getDataFromAPI();
                    this.hotProposal = this.vote_info.getProposalFromAPI();
                });
            });
        },
        getDAOInfo: function () {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.getProposalList();
                yield getCirculate().then((result) => {
                    this.totalSupply = ethers.utils.parseEther(result.circulating);
                });
                for (const event of this.list_event) {
                    yield this.contract.getProposal(event.id).then((result) => __awaiter(this, void 0, void 0, function* () {
                        event.event_name = result[1];
                        event.time_to_start = result[2].toString();
                        event.time_to_end = result[3].toString();
                        yield this.getEventStage(event);
                        yield this.getVote(event);
                        const timeNow = new Date().getTime();
                        const timeDifference = event.time_to_end * 1000 - timeNow;
                        if (timeDifference < 0) {
                            event.is_openVote = true;
                        }
                    }));
                    yield this.contract.isVotingOpen(event.id).then((isVote) => {
                        event.is_voted = isVote;
                    });
                }
                this.isFetch = false;
            });
        },
        getEventStage: function (event) {
            return __awaiter(this, void 0, void 0, function* () {
                event.event_stage = EventState.None;
                const timeNow = new Date().getTime();
                const timeDifference = event.time_to_end * 1000 - timeNow;
                const timeDiffStart = event.time_to_start * 1000 - timeNow;
                if (timeDiffStart > 0) {
                    event.event_stage = EventState.CommingSoon;
                }
                else if (timeDifference < 0) {
                    event.event_stage = EventState.Expired;
                }
                else {
                    event.event_stage = EventState.Open;
                }
            });
        },
        getVote: function (event) {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.contract.votingPercentages(event.id, this.totalSupply).then((result) => {
                    event.noCount_percent = +ethers.utils.formatUnits(result[0].toString(), 2).toString();
                    event.noCount = +ethers.utils.formatUnits(result[1].toString(), process.env.VUE_APP_MAIN_TOKEN_DECIMAL).toString();
                    event.yesCount_percent = +ethers.utils.formatUnits(result[2].toString(), 2).toString();
                    event.yesCount = +ethers.utils.formatUnits(result[3].toString(), process.env.VUE_APP_MAIN_TOKEN_DECIMAL).toString();
                }).catch(() => {
                    event.noCount_percent = event.noCount = 0;
                    event.yesCount_percent = event.yesCount = 0;
                });
            });
        }
    },
    computed: Object.assign(Object.assign({}, mapGetters(MOUDLE_WEB3_NAME, ['getProvider'])), { checkPlatform() {
            return isMobile();
        },
        resultQuery() {
            if (this.searchEventsName) {
                if (this.current_stage_search === 0) {
                    return this.list_event.filter((item) => {
                        return this.searchEventsName
                            .toLowerCase()
                            .split(" ")
                            .every((v) => item.event_name.toLowerCase().includes(v));
                    });
                }
                return this.list_event.filter((item) => {
                    return this.searchEventsName
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.event_name.toLowerCase().includes(v) && item.event_stage === this.current_stage_search);
                });
            }
            else {
                if (this.current_stage_search === 0) {
                    return this.list_event;
                }
                return this.list_event.filter((item) => {
                    return item.event_stage === this.current_stage_search;
                });
            }
        } }),
    watch: {},
    created() {
    },
    updated() {
        this.isExactActive = typeof this.$refs.rvdao === 'undefined';
    },
    mounted() {
        if (this.getProvider) {
            this.createContractObj().then(() => __awaiter(this, void 0, void 0, function* () {
                yield this.getDAOInfo();
            }));
        }
        this.isExactActive = typeof this.$refs.rvdao === 'undefined';
    },
};
