import { EventState, EventVote, OptionResultYesNoVote } from "@/entities/ProposalDetail";
import { CssEventStage, startTimerList } from "@/helper/dao";
import formatNumber from "@/helper/number";
export default {
    computed: {
        EventVote() {
            return EventVote;
        },
        getTimeEstimate() {
            return this.timerOutput;
        },
        getEventStage() {
            return this.eventStage;
        }
    },
    data() {
        return {
            CssEventStage,
            startTimerList,
            timerOutput: "",
        };
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        symbol: {
            type: String,
            required: true,
        },
        eventStage: {
            type: Number,
            required: true,
            default: EventState.None,
        },
        Option_1: {
            type: String,
            required: false,
            default: OptionResultYesNoVote.InFavor,
        },
        Option_2: {
            type: String,
            required: false,
            default: OptionResultYesNoVote.Against,
        },
    },
    watch: {},
    mounted() {
        setInterval(() => {
            this.timerOutput = this.startTimerList();
        }, 1000);
    },
    methods: {
        formatNumber,
        getResultWinner() {
            if (!this.event.is_voted) {
                if (this.event.yesCount_percent > this.event.noCount_percent) {
                    return EventVote.Options_1;
                }
                else {
                    return EventVote.Options_2;
                }
            }
            return EventVote.None;
        },
        goToDetails(id) {
            this.$router.push({
                name: "DAO Vote",
                params: {
                    id: id,
                    event: this.event,
                    symbol: this.symbol,
                    Option_1: this.Option_1,
                    Option_2: this.Option_2,
                }
            });
        },
    }
};
