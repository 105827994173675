import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import SideBar from "../components/SideBar.vue";
import BreadCrumb from "../components/BreadCrumb.vue";
export default {
    components: {
        Header,
        Footer,
        SideBar,
        BreadCrumb,
    },
    data() {
        return {
            year: ""
        };
    },
    mounted() {
        let date = new Date();
        this.year = date.getFullYear();
    }
};
