import { __awaiter } from "tslib";
import { getStatusEvent } from "@/api/transaction/get";
import * as defineStatus from "@/define/event-status";
export class GetStatusEventUseCases {
    constructor() {
        this.data = {};
    }
    getDataFromAPI(event_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const api = yield getStatusEvent(event_id);
            if (api) {
                this.data = api.data;
            }
        });
    }
    getData() {
        return this.data;
    }
    checkStatusEvent(id) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getDataFromAPI(id);
            let result = this.getData();
            if (result == null) {
                return defineStatus.EVENT_NONE;
            }
            return result.event_status;
        });
    }
}
