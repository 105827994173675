import { __awaiter } from "tslib";
import store from "@/store/index";
import { GetStatusEventUseCases } from "@/usecases/GetStatusEventUseCases";
import * as defineStatus from "@/define/event-status";
import { GetTypeEventUseCases } from "@/usecases/GetTypeEventUseCases";
export function validateRouter(event_id, type) {
    return __awaiter(this, void 0, void 0, function* () {
        let statusEvent = new GetStatusEventUseCases();
        let typeEvent = new GetTypeEventUseCases();
        if ((yield statusEvent.checkStatusEvent(event_id)) !== defineStatus.EVENT_OPEN) {
            return false;
        }
        if ((yield typeEvent.checkTypeEvent(event_id)) !== type) {
            return false;
        }
        if (!store.getters.IsLogin || !store.getters.IsWalletHolder) {
            return false;
        }
        return true;
    });
}
