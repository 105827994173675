import { __awaiter } from "tslib";
import { getCheckReg } from "@/api/transaction/get";
export class GetCheckRegUseCases {
    constructor() {
        this.isReg = {};
    }
    getDataFromAPI(event_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const api = yield getCheckReg(event_id);
            if (api == undefined) {
                return;
            }
            this.isReg.message = api.message;
        });
    }
    getData() {
        return this.isReg.message;
    }
}
