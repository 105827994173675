export var EventVote;
(function (EventVote) {
    EventVote[EventVote["None"] = 0] = "None";
    EventVote[EventVote["Options_1"] = 1] = "Options_1";
    EventVote[EventVote["Options_2"] = 2] = "Options_2";
    EventVote[EventVote["Total"] = 3] = "Total";
})(EventVote || (EventVote = {}));
export var EventState;
(function (EventState) {
    EventState[EventState["None"] = 0] = "None";
    EventState[EventState["CommingSoon"] = 1] = "CommingSoon";
    EventState[EventState["Open"] = 2] = "Open";
    EventState[EventState["Expired"] = 3] = "Expired";
    EventState[EventState["Total"] = 4] = "Total";
})(EventState || (EventState = {}));
export var OptionResultYesNoVote;
(function (OptionResultYesNoVote) {
    OptionResultYesNoVote["None"] = "None";
    OptionResultYesNoVote["InFavor"] = "In Favor";
    OptionResultYesNoVote["Against"] = "Against";
})(OptionResultYesNoVote || (OptionResultYesNoVote = {}));
export var OptionResultChooseVote;
(function (OptionResultChooseVote) {
    OptionResultChooseVote["None"] = "None";
    OptionResultChooseVote["Option1"] = "Option 1";
    OptionResultChooseVote["Option2"] = "Option 2";
})(OptionResultChooseVote || (OptionResultChooseVote = {}));
export var OptionResultType;
(function (OptionResultType) {
    OptionResultType[OptionResultType["YesNoQuestion"] = 1] = "YesNoQuestion";
    OptionResultType[OptionResultType["Choose"] = 2] = "Choose";
})(OptionResultType || (OptionResultType = {}));
