import { getRequest } from '../request';
import { ResponseData } from "@/api/transaction/trans";
export const getCardMMC = () => {
    let path = "/portfolio/getvol";
    return getRequest(path, {}).then(ResponseData);
};
export const getListEvent = (offset, limit) => {
    let path = "/event/list";
    return getRequest(path, {
        "offset": offset,
        "limit": limit
    }).then(ResponseData);
};
export const getEventRegList = (offset, limit, event_id) => {
    let path = "/event_register/history";
    return getRequest(path, {
        "offset": offset,
        "limit": limit,
        "event_id": event_id
    }).then(ResponseData);
};
export const getEventPostList = (offset, limit, event_id) => {
    let path = "/event_post/history";
    return getRequest(path, {
        "offset": offset,
        "limit": limit,
        "event_id": event_id
    }).then(ResponseData);
};
export const getListContent = (offset, limit, event_id) => {
    let path = "/event_post/contest";
    return getRequest(path, {
        "offset": offset,
        "limit": limit,
        "event_id": event_id
    }).then(ResponseData);
};
export const getListHistoryVote = (offset, limit, event_id) => {
    let path = "/event_log/history";
    return getRequest(path, {
        "offset": offset,
        "limit": limit,
        "event_id": event_id
    }).then(ResponseData);
};
export const getCheckRef = () => {
    let path = "/ref/check";
    return getRequest(path).then(ResponseData);
};
export const getHisRef = (offset, limit) => {
    let path = "/ref/get_history";
    return getRequest(path, {
        "offset": offset,
        "limit": limit
    }).then(ResponseData);
};
export const getCheckReg = (event_id) => {
    let path = "/event_register/check";
    return getRequest(path, {
        "event_id": event_id
    }).then(ResponseData);
};
export const getStatusEvent = (event_id) => {
    let path = "/event/status";
    return getRequest(path, {
        "event_id": event_id
    }).then(ResponseData);
};
export const getRefCode = (event_id) => {
    let path = "/refcode/get";
    return getRequest(path, {
        "eventId": event_id
    }).then(ResponseData);
};
export const getTypeEvent = (event_id) => {
    let path = "/event/type";
    return getRequest(path, {
        "event_id": event_id
    }).then(ResponseData);
};
