import { __awaiter } from "tslib";
import { sliceWalletID } from "@/helper/slice-content";
import isMobile from "@/helper/platform";
import ShowInfo from "@/modal/ShowInfo.vue";
import { AirDropInfoUseCase } from "@/usecases/GetAirDropInfoUseCase";
import ClaimInfo from "@/components/airdrop/ClaimInfo.vue";
import { ethers } from "ethers";
import { mapGetters } from "vuex";
import { MOUDLE_WEB3_NAME } from "@/store/modules/web3/constant";
import AirdropABI from '@/abi/AirdropDistributor.json';
import formatNumber from "@/helper/number";
export default {
    components: {
        ShowInfo,
        ClaimInfo
    },
    data() {
        return {
            sliceWalletID,
            formatNumber,
            event_detail: {
                id: 1,
                is_eligible: false,
                banner_home: "banner-home",
                banner_detail: "banner-detail",
                wallet: {
                    address: "",
                    hold: "",
                    quantity: "",
                    claim_burn: "",
                },
                btn_claim: true,
                btn_burn: true,
            },
            open_confirm: false,
            open_done: false,
            open_error: false,
            opened: [],
            airdrop_info: new AirDropInfoUseCase(),
            detail_airdrop: {},
            range_claim: 0,
            in_claim: "",
            range_burn: 0,
            in_burn: "",
            range_contribute: 0,
            in_contribute: "",
            showLoading: true,
            contract: null,
            isSubmitTransaction: false,
        };
    },
    create() {
    },
    mounted() {
        if (this.getProvider) {
            this.createContractObj().then(() => {
                this.getAirDropInfo();
            });
        }
    },
    computed: Object.assign(Object.assign({}, mapGetters(MOUDLE_WEB3_NAME, ['IsLogin', 'getAddress', 'getProvider'])), { checkPlatform() {
            return isMobile();
        },
        remainingReward() {
            let rs = this.detail_airdrop.received;
            if (this.detail_airdrop.received === undefined || this.detail_airdrop.received === null || this.detail_airdrop.received === "") {
                return 0;
            }
            return rs - parseInt(this.range_claim) - parseInt(this.range_burn) - parseInt(this.range_contribute);
        },
        isContinue() {
            return parseInt(this.totalUse) === parseInt(this.detail_airdrop.received) && this.detail_airdrop.action === 0 && !this.isSubmitTransaction;
        },
        totalUse() {
            return Number(this.range_burn) + Number(this.range_claim) + Number(this.range_contribute);
        },
        claimData() {
            const rs = {
                claim: this.range_claim,
                burn: this.range_burn,
                contribute: this.range_contribute,
            };
            return rs;
        },
        isClaimMT50() {
            return this.range_claim >= (this.detail_airdrop.received / 2).toFixed(0);
        } }),
    watch: {
        'range_claim': function () {
            const sliderElement = document.getElementById('range_claim');
            const tooltipElement = document.getElementById('claim_tooltip');
            let percentage = 100 / this.detail_airdrop.received * this.range_claim;
            if ((sliderElement != null) && (tooltipElement != null)) {
                let sliderWidth = sliderElement.offsetWidth;
                let tooltipWidth = tooltipElement.offsetWidth;
                let calc = sliderWidth - tooltipWidth;
                let positionCalc = (percentage / 100) * calc;
                tooltipElement.style.left = positionCalc + 'px';
            }
        },
        'range_burn': function () {
            const sliderElement = document.getElementById('range_burn');
            const tooltipElement = document.getElementById('burn_tooltip');
            if ((sliderElement != null) && (tooltipElement != null)) {
                let percentage = 100 / this.detail_airdrop.received * this.range_burn;
                let sliderWidth = sliderElement.offsetWidth;
                let tooltipWidth = tooltipElement.offsetWidth;
                let calc = sliderWidth - tooltipWidth;
                let positionCalc = (percentage / 100) * calc;
                tooltipElement.style.left = positionCalc + 'px';
            }
        },
        'range_contribute': function () {
            const sliderElement = document.getElementById('range_contribute');
            const tooltipElement = document.getElementById('contribute_tooltip');
            let percentage = 100 / this.detail_airdrop.received * this.range_contribute;
            if ((sliderElement != null) && (tooltipElement != null)) {
                let sliderWidth = sliderElement.offsetWidth;
                let tooltipWidth = tooltipElement.offsetWidth;
                let calc = sliderWidth - tooltipWidth;
                let positionCalc = (percentage / 100) * calc;
                tooltipElement.style.left = positionCalc + 'px';
            }
        },
        'detail_airdrop': function () {
            if (this.detail_airdrop && Object.keys(this.detail_airdrop).length !== 0) {
                this.showLoading = !this.showLoading;
            }
        },
    },
    methods: {
        eventID() {
            return parseInt(this.$route.params.eventID);
        },
        createContractObj() {
            return __awaiter(this, void 0, void 0, function* () {
                const signer = this.getProvider.getSigner();
                this.contract = new ethers.Contract(process.env.VUE_APP_AIRDROP_CONTRACT || "", AirdropABI, signer);
            });
        },
        getAirDropInfo() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.airdrop_info.getData(this.getAddress, parseInt(this.eventID()));
                this.detail_airdrop = this.airdrop_info.getDataFromAPI();
                this.detail_airdrop.is_eligible = this.detail_airdrop.address !== "" && this.detail_airdrop.amount !== "" && this.detail_airdrop.reward !== "" && this.detail_airdrop.received !== "";
                if (this.detail_airdrop.id !== this.eventID()) {
                    return yield this.$router.push({ path: "/" });
                }
            });
        },
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1);
            }
            else {
                this.opened.push(id);
            }
        },
        handleBack() {
            this.$router.push({ name: "Events" });
        },
        openModalConfirm() {
            this.open_confirm = true;
        },
        handleConfirm(e) {
            e.preventDefault();
            this.hideModal(0);
            this.isSubmitTransaction = true;
            this.handleClaimContract();
        },
        handleClaimContract() {
            return __awaiter(this, void 0, void 0, function* () {
                let self = this;
                this.contract.claim(Number(this.range_claim), Number(this.range_burn), Number(this.range_contribute), this.detail_airdrop.merkleproof).then(function () {
                    self.detail_airdrop.action = 1;
                    self.open_done = true;
                    self.airdrop_info.updateActionHolder(self.getAddress);
                }).catch(function () {
                    self.open_error = true;
                });
            });
        },
        hideModal(type) {
            // type
            // 0: confirm
            // 1: done
            // 2: error
            switch (type) {
                case 0:
                    this.open_confirm = false;
                    break;
                case 1:
                    this.open_done = false;
                    this.getAirDropInfo();
                    this.isSubmitTransaction = false;
                    break;
                case 2:
                    this.open_error = false;
                    this.getAirDropInfo();
                    this.isSubmitTransaction = false;
                    break;
            }
        },
        handleRange(e) {
            let model = e.target.id;
            let value = e.target.value;
            switch (model) {
                case "range_claim":
                    this.in_claim = value;
                    if (this.totalUse > this.detail_airdrop.received) {
                        this.handleMaxReceived("range_claim");
                    }
                    this.handleInput('in_claim');
                    break;
                case "in_claim":
                    this.range_claim = value === "" ? 0 : value.toString().replace(/[^0-9]/g, '');
                    if (this.totalUse > this.detail_airdrop.received) {
                        this.handleMaxReceived("range_claim");
                    }
                    this.handleInput('in_claim');
                    break;
                case "range_burn":
                    this.in_burn = value;
                    if (this.totalUse > this.detail_airdrop.received) {
                        this.handleMaxReceived("range_burn");
                    }
                    this.handleInput('in_burn');
                    break;
                case "in_burn":
                    this.range_burn = value === "" ? 0 : value.toString().replace(/[^0-9]/g, '');
                    if (this.totalUse > this.detail_airdrop.received) {
                        this.handleMaxReceived("range_burn");
                    }
                    this.handleInput('in_burn');
                    break;
                case "range_contribute":
                    this.in_contribute = value;
                    if (this.totalUse > this.detail_airdrop.received) {
                        this.handleMaxReceived("range_contribute");
                    }
                    this.handleInput('in_contribute');
                    break;
                case "in_contribute":
                    this.range_contribute = value === "" ? 0 : value.toString().replace(/[^0-9]/g, '');
                    if (this.totalUse > this.detail_airdrop.received) {
                        this.handleMaxReceived("range_contribute");
                    }
                    this.handleInput('in_contribute');
                    break;
            }
        },
        handleMaxReceived(model) {
            switch (model) {
                case "range_claim":
                    this.in_claim = this.detail_airdrop.received - this.range_burn - this.range_contribute;
                    this.range_claim = this.in_claim;
                    break;
                case "range_burn":
                    this.in_burn = this.detail_airdrop.received - this.range_claim - this.range_contribute;
                    this.range_burn = this.in_burn;
                    break;
                case "range_contribute":
                    this.in_contribute = this.detail_airdrop.received - this.range_burn - this.range_claim;
                    this.range_contribute = this.in_contribute;
                    break;
            }
        },
        handleInput(model) {
            if (this[model] == 0) {
                this[model] = "";
                return;
            }
            this[model] += ".";
            this[model] = this[model].replace(/[^0-9.]/g, '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,').slice(0, -1);
        },
        onClickMax(model) {
            switch (model) {
                case "in_claim": {
                    let max = this.detail_airdrop.received - this.range_burn - this.range_contribute;
                    this.in_claim = max;
                    let prams = {
                        target: {
                            id: "in_claim",
                            value: max
                        }
                    };
                    this.handleRange(prams);
                    break;
                }
                case "in_burn": {
                    let max = this.detail_airdrop.received - this.range_claim - this.range_contribute;
                    this.in_burn = max;
                    let prams = {
                        target: {
                            id: "in_burn",
                            value: max
                        }
                    };
                    this.handleRange(prams);
                    break;
                }
                case "in_contribute": {
                    let max = this.detail_airdrop.received - this.range_burn - this.range_claim;
                    this.in_contribute = max;
                    let prams = {
                        target: {
                            id: "in_contribute",
                            value: max
                        }
                    };
                    this.handleRange(prams);
                    break;
                }
            }
        },
        contact() {
            window.open("https://roboglobal.info/linktree", "_blank");
            this.hideModal(2);
        }
    },
};
