import { SET_WEB3_ONBOARD, SET_PROVIDER, SET_IS_ENABLE, SET_HOLDER, SET_ID_TOKEN, SET_CONNECTED_WALLET, SET_SIGNATURE, SET_ADDRESS, SET_PROCESSING, CLEAN } from "@/store/modules/web3/constant";
export const mutations = {
    [SET_WEB3_ONBOARD](state, web3) {
        state.web3Onboard = web3;
    },
    [SET_PROVIDER](state, provider) {
        state.provider = provider;
    },
    [SET_IS_ENABLE](state, isEnable) {
        state.isEnable = isEnable;
    },
    [SET_HOLDER](state, holder) {
        state.holder = holder;
        localStorage.setItem("holder", String(holder));
    },
    [SET_ID_TOKEN](state, idToken) {
        state.idToken = idToken;
        localStorage.setItem("idToken", idToken);
    },
    [SET_CONNECTED_WALLET](state, wallet) {
        state.connectedWallet = wallet;
    },
    [SET_SIGNATURE](state, signature) {
        state.signature = signature;
        localStorage.setItem("signature", signature);
    },
    [SET_ADDRESS](state, address) {
        state.address = address;
        localStorage.setItem("address", address);
    },
    [SET_PROCESSING](state, processing) {
        state.processing = processing;
    },
    [CLEAN](state) {
        state.idToken = '';
        state.holder = false;
        state.isEnable = false;
        state.connectedWallet = {};
        state.signature = null;
        state.address = null;
        // localStorage.removeItem("idToken");
        // localStorage.clear();
    }
};
