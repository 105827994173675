import { __awaiter } from "tslib";
import { EventRegUseCases } from "@/usecases/PostFormRegisterUseCases";
import { toastMessage } from "@/lib/toast";
import isMobile from "@/helper/platform";
export default {
    components: {},
    data() {
        return {
            userProfile: {
                event_id: 0,
                telegram: "",
                twitter: "",
                reddit: "",
            },
            inputTele: false,
            inputTwitter: false,
            inputReddit: false,
            data: new EventRegUseCases(),
            opened: [],
            isDisable: false,
        };
    },
    methods: {
        checkNullInput() {
            this.inputTele = this.userProfile.telegram === "";
            this.inputTwitter = this.userProfile.twitter === "";
            this.inputReddit = this.userProfile.reddit === "";
        },
        submitRegister() {
            return __awaiter(this, void 0, void 0, function* () {
                if (!this.checkEnterInfor) {
                    this.getEventID();
                    this.isDisable = true;
                    this.data.getDataFromForm(this.userProfile);
                    yield this.data
                        .postData()
                        .then(() => {
                        this.showToastDone();
                        this.$router.go();
                    })
                        .catch(() => {
                        this.showToastErr();
                        this.isDisable = false;
                    }).finally(() => {
                        this.userProfile.telegram = "";
                        this.userProfile.twitter = "";
                        this.userProfile.reddit = "";
                    });
                }
            });
        },
        getEventID() {
            this.userProfile.event_id = Number(this.$route.params.eventID);
        },
        showToastErr() {
            this.$toast.open({
                message: toastMessage.err,
                type: "error",
                position: "top-right",
                duration: 5000,
            });
        },
        showToastDone() {
            this.$toast.open({
                message: toastMessage.registerSuccess,
                type: "success",
                position: "top-right",
                duration: 5000,
            });
        },
    },
    created() {
        return __awaiter(this, void 0, void 0, function* () {
        });
    },
    watch: {},
    computed: {
        checkPlatform() {
            return isMobile();
        },
        checkEnterInfor() {
            this.checkNullInput();
            if (this.inputTwitter && this.inputReddit && this.inputTele)
                return true;
            return false;
        },
    },
};
