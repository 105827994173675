import { __awaiter } from "tslib";
import { PostLinkRegUseCases } from "@/usecases/PostLinkRegUseCases";
import { GetEventPostListUseCases } from "@/usecases/GetEventPostListUseCases";
import { toastMessage } from "@/lib/toast";
import { formatDate } from "@/helper/format-date";
import { sliceContentMobile } from "@/helper/slice-content";
import isMobile from "@/helper/platform";
import RegisterInfo from "@/containers/event/RegisterInfo.vue";
import EventRegister from "@/containers/event/EventRegister.vue";
import { GetCheckRegUseCases } from "@/usecases/GetCheckRegUseCases";
import * as defineType from "@/define/event-type";
import { validateRouter } from "@/helper/router-condition";
export default {
    components: {
        EventRegister,
        RegisterInfo
    },
    data() {
        return {
            inputBlank: false,
            link: new PostLinkRegUseCases(),
            postData: {
                content: "",
                event_id: 0,
            },
            list: new GetEventPostListUseCases(),
            history: [],
            isDisable: false,
            opened: [],
            isReadOnly: false,
            placeHolderInput: 'Please enter your link',
            checkReg: new GetCheckRegUseCases(),
            isReg: undefined,
        };
    },
    methods: {
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1);
            }
            else {
                this.opened.push(id);
            }
        },
        checkNullInput() {
            this.inputBlank = this.postData.content === "";
        },
        register(isDisable, err, done) {
            return __awaiter(this, void 0, void 0, function* () {
                this.checkNullInput();
                if (this.postData.content) {
                    this.isDisable = true;
                    this.postData.event_id = Number(this.$route.params.eventID);
                    this.link.getDataFromForm(this.postData);
                    yield this.link
                        .postData()
                        .then(() => {
                        done();
                        this.getHistory().finally(() => {
                            isDisable = this.history.length >= 3;
                        });
                    })
                        .catch(() => {
                        err();
                        isDisable = false;
                    }).finally(() => {
                        this.postData.content = "";
                    });
                    this.isDisable = isDisable;
                }
            });
        },
        getHistory() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.list.getDataListHistory(0, 100, Number(this.$route.params.eventID));
                this.history = this.list.getDataFromAPI();
                formatDate(this.history);
            });
        },
        sliceContentMobile(obj) {
            return sliceContentMobile(obj);
        },
        getCheckReg() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.checkReg.getDataFromAPI(Number(this.$route.params.eventID));
                this.isReg = this.checkReg.getData();
            });
        },
        showToastErr() {
            this.$toast.open({
                message: toastMessage.err,
                type: "error",
                position: "top-right",
                duration: 5000,
            });
        },
        showToastDone() {
            this.$toast.open({
                message: toastMessage.postSuccess,
                type: "success",
                position: "top-right",
                duration: 5000,
            });
        },
        showToastLogin() {
            this.$toast.open({
                message: toastMessage.login,
                type: "error",
                position: "top-right",
                duration: 5000,
            });
        },
        showToastHolder() {
            this.$toast.open({
                message: toastMessage.holder,
                type: "error",
                position: "top-right",
                duration: 5000,
            });
        },
    },
    created() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield validateRouter(Number(this.$route.params.eventID), defineType.EVENT_POST))) {
                return this.$router.push('/403');
            }
            yield this.getCheckReg();
            if (this.isReg) {
                yield this.getHistory();
            }
        });
    },
    watch: {
        history: function () {
            if (this.history !== null) {
                if (this.history.length >= 3) {
                    this.isDisable = true;
                    this.isReadOnly = true;
                    this.placeHolderInput = "You have reached the limit of 3 contents";
                }
            }
        },
    },
    computed: {
        showRegister() {
            if (this.isReg === undefined)
                return false;
            return !this.isReg;
        },
        showPostForm() {
            if (this.isReg === undefined)
                return false;
            return this.isReg;
        },
        checkPlatform() {
            return isMobile();
        },
    },
};
