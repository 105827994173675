import { __awaiter } from "tslib";
import { GetListContentUseCases } from "@/usecases/GetListContentUseCases";
import { PostContentVoteUseCases } from "@/usecases/PostContentVoteUseCases";
import { getListHistoryVoteUseCases } from "@/usecases/GetListHistoryVoteUseCases";
import isMobile from "@/helper/platform";
import { toastMessage } from "@/lib/toast";
import { formatDateLowerCase } from '@/helper/format-date';
import { sliceContentMobile } from "@/helper/slice-content";
import * as defineStatus from "@/define/event-status";
import { GetStatusEventUseCases } from "@/usecases/GetStatusEventUseCases";
export default {
    components: {},
    data() {
        return {
            contentVote: new GetListContentUseCases(),
            listContentVote: [],
            postContentVote: new PostContentVoteUseCases(),
            postVote: {
                content: 1,
                event_id: 0,
                event_post_id: 0,
            },
            voteHistory: new getListHistoryVoteUseCases(),
            listHistory: [],
            isDisable: false,
            opened: [],
            statusEvent: new GetStatusEventUseCases(),
        };
    },
    methods: {
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1);
            }
            else {
                this.opened.push(id);
            }
        },
        getListVote() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.contentVote.getDataListHistory(0, 100, Number(this.$route.params.eventID));
                this.listContentVote = this.contentVote.getDataFromAPI();
            });
        },
        submitVote(value, err, done) {
            return __awaiter(this, void 0, void 0, function* () {
                this.postVote.event_id = value.event_id;
                this.postVote.event_post_id = value.event_post_id;
                this.postContentVote.getDataFromForm(this.postVote);
                value.is_vote = true;
                yield this.postContentVote
                    .postData()
                    .then(function () {
                    done();
                })
                    .catch(function () {
                    err();
                    value.is_vote = false;
                });
                yield this.getListHistory();
            });
        },
        getListHistory() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.voteHistory.getDataListHistory(0, 100, Number(this.$route.params.eventID));
                this.listHistory = this.voteHistory.getDataFromAPI();
                formatDateLowerCase(this.listHistory);
            });
        },
        showToastErr() {
            this.$toast.open({
                message: toastMessage.err,
                type: "error",
                position: "top-right",
                duration: 5000,
            });
        },
        showToastDone() {
            this.$toast.open({
                message: toastMessage.voteSuccess,
                type: "success",
                position: "top-right",
                duration: 5000,
            });
        },
        sliceContentMobile(obj) {
            return sliceContentMobile(obj);
        },
        sliceContentMobileHistory(obj) {
            if (isMobile()) {
                let temp = obj.event_post_content;
                if (temp.length > 30)
                    return temp = temp.slice(0, 30) + "...";
                return temp;
            }
            return obj.event_post_content;
        },
        changeClassBtn(value) {
            if (value.is_vote) {
                return "btn-success";
            }
            else {
                if (!this.isDisable) {
                    return "btn-primary";
                }
                else
                    return "btn-secondary";
            }
        },
        changeTextBtn(value) {
            if (value.is_vote) {
                return "Voted";
            }
            else
                return "vote";
        }
    },
    created() {
        return __awaiter(this, void 0, void 0, function* () {
            if ((yield this.statusEvent.checkStatusEvent(Number(this.$route.params.eventID))) !== defineStatus.EVENT_VOTE) {
                return this.$router.push('/403');
            }
            yield Promise.all([this.getListVote(), this.getListHistory()]);
        });
    },
    watch: {
        listHistory: function () {
            if (this.listHistory !== null) {
                if (this.listHistory.length >= 3) {
                    this.isDisable = true;
                }
            }
        },
    },
    computed: {
        checkPlatform() {
            return isMobile();
        },
    },
};
