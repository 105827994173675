import { postRequest } from '../request';
import { ResponseData } from "@/api/transaction/trans";
export const postUserProfileRegister = (params) => {
    let path = "/event_register/register";
    return postRequest(path, params).then(ResponseData);
};
export const postLinkReg = (params) => {
    let path = "/event_post/register_content";
    return postRequest(path, params).then(ResponseData);
};
export const postContentVote = (params) => {
    let path = "/event_log/vote_content";
    return postRequest(path, params).then(ResponseData);
};
export const postRef = (params) => {
    let path = "/ref/add";
    return postRequest(path, params).then(ResponseData);
};
export const postIDToGetCodeRef = (params) => {
    let path = "/refcode/gen";
    return postRequest(path, params).then(ResponseData);
};
