import { __awaiter } from "tslib";
import isMobile from "@/helper/platform";
import ShowInfo from "@/modal/ShowInfo.vue";
import { mapGetters } from "vuex";
import { MOUDLE_WEB3_NAME } from "@/store/modules/web3/constant";
import MigrationABI from '@/abi/Migration.json';
import formatNumber from "@/helper/number";
import { MigrateInfoUseCase } from "@/usecases/GetMigrateUseCase";
import { ethers } from "ethers";
export default {
    components: {
        ShowInfo,
    },
    data() {
        return {
            formatNumber,
            open_confirm: false,
            open_done: false,
            open_error: false,
            opened: [],
            airdrop_info: new MigrateInfoUseCase(),
            detail_airdrop: {},
            showLoading: true,
            contract: null,
            isSubmitTransaction: false,
        };
    },
    mounted() {
        if (this.getProvider) {
            this.createContractObj().then(() => {
                this.getMigrationInfo();
            });
        }
    },
    computed: Object.assign(Object.assign({}, mapGetters(MOUDLE_WEB3_NAME, ['IsLogin', 'getAddress', 'getProvider'])), { checkPlatform() {
            return isMobile();
        },
        isContinue() {
            return this.detail_airdrop.claimed === false && !this.isSubmitTransaction;
        } }),
    watch: {
        'detail_airdrop': function () {
            if (this.detail_airdrop && Object.keys(this.detail_airdrop).length !== 0) {
                this.showLoading = !this.showLoading;
            }
        },
    },
    methods: {
        createContractObj() {
            return __awaiter(this, void 0, void 0, function* () {
                const signer = this.getProvider.getSigner();
                this.contract = new ethers.Contract(process.env.VUE_APP_MIGRATE_CONTRACT || "", MigrationABI, signer);
            });
        },
        getMigrationInfo() {
            return __awaiter(this, void 0, void 0, function* () {
                yield this.airdrop_info.getData(this.getAddress);
                this.detail_airdrop = this.airdrop_info.getDataFromAPI();
            });
        },
        handleBack() {
            this.$router.push({ name: "Events" });
        },
        openModalConfirm() {
            this.open_confirm = true;
        },
        handleConfirm(e) {
            e.preventDefault();
            this.hideModal(0);
            this.isSubmitTransaction = true;
            this.handleClaimContract();
        },
        handleClaimContract() {
            return __awaiter(this, void 0, void 0, function* () {
                let self = this;
                let claimAmount = ethers.utils.parseUnits(this.detail_airdrop.amount, 9);
                yield this.contract.claim(claimAmount, Number(this.detail_airdrop.Id), this.detail_airdrop.signature).then(function () {
                    self.detail_airdrop.claimed = true;
                    self.open_done = true;
                }).catch(function (error) {
                    console.error(error);
                    self.open_error = true;
                });
            });
        },
        hideModal(type) {
            switch (type) {
                case 0:
                    this.open_confirm = false;
                    break;
                case 1:
                    this.open_done = false;
                    this.getMigrationInfo();
                    this.isSubmitTransaction = false;
                    break;
                case 2:
                    this.open_error = false;
                    this.getMigrationInfo();
                    this.isSubmitTransaction = false;
                    break;
            }
        },
        contact() {
            window.open("https://roboglobal.info/linktree", "_blank");
            this.hideModal(2);
        }
    },
};
