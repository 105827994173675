import { render, staticRenderFns } from "./GetRefCode.vue?vue&type=template&id=68fa0f06&scoped=true&"
import script from "./GetRefCode.vue?vue&type=script&lang=js&"
export * from "./GetRefCode.vue?vue&type=script&lang=js&"
import style0 from "./GetRefCode.vue?vue&type=style&index=0&id=68fa0f06&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68fa0f06",
  null
  
)

export default component.exports