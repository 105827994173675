import { __awaiter } from "tslib";
import axios from "axios";
import { getIdToken } from "@/api/token";
const BASE_URL = "https://api.roboglobal.info/v1/token";
export function checkHolderToken(address) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield axios.get(BASE_URL + "/holder", {
                params: {
                    wallet: address,
                    token: process.env.VUE_APP_MAIN_TOKEN_CONTRACT,
                    network: process.env.VUE_APP_NETWORK
                },
                withCredentials: false,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': getIdToken(),
                }
            });
        }
        catch (error) {
            return undefined;
        }
    });
}
