import { __awaiter } from "tslib";
export function signMessage(provider, nonce) {
    return __awaiter(this, void 0, void 0, function* () {
        const rawMessage = "Welcome to Robo Global Investment!\n\n Click to sign with nonce: " + nonce + ".\nIt means that I accept RGI Terms of Service https://roboglobal.info/term\n\n Happy discovery!";
        try {
            const signer = provider.getSigner();
            return yield signer.signMessage(rawMessage);
        }
        catch (error) {
            return null;
        }
    });
}
