import { __awaiter } from "tslib";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import ShowInfo from "@/modal/ShowInfo.vue";
import { sliceWalletID } from "@/helper/slice-content";
import { toastMessage } from "@/lib/toast";
import { EventVote, OptionResultYesNoVote } from "@/entities/ProposalDetail";
import { CssEventStage, startTimerList } from "@/helper/dao";
import { ethers } from "ethers";
import VotingABI from "@/abi/Voting.json";
import ERC20ABI from "@/abi/ERC20.json";
import { mapGetters } from "vuex";
import { MOUDLE_WEB3_NAME } from "@/store/modules/web3/constant";
import formatNumber from "@/helper/number";
import { VoteAddressInfoUseCase } from "@/usecases/GetVoteAddressInfoUseCase";
export default {
    components: {
        Header,
        Footer,
        SideBar,
        BreadCrumb,
        ShowInfo,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        symbol: {
            type: String,
            required: true,
        },
        Option_1: {
            type: String,
            required: false,
            default: OptionResultYesNoVote.InFavor,
        },
        Option_2: {
            type: String,
            required: false,
            default: OptionResultYesNoVote.Against,
        },
    },
    data() {
        return {
            sliceWalletID,
            CssEventStage,
            startTimerList,
            formatNumber,
            selected_vote: OptionResultYesNoVote.None,
            selected_list_vote: this.Option_1,
            modal_selected_list_vote: "",
            is_open: false,
            is_open_confirm: false,
            vote_detail: new VoteAddressInfoUseCase(),
            current_block: 0,
            current_amount: 0,
            timerOutput: "",
            isCanVote: false,
            contract: null,
            token: null,
            contractTokenAddr: process.env.VUE_APP_MAIN_TOKEN_CONTRACT,
            contractAddr: process.env.VUE_APP_DAO_CONTRACT,
        };
    },
    mounted() {
        if (this.getProvider) {
            this.createContractObj().then(() => {
                this.getVoteDetail();
                this.getAmountHolder();
                this.getAddressVote();
            });
        }
        setInterval(() => {
            this.timerOutput = this.startTimerList();
        }, 1000);
    },
    methods: {
        createContractObj() {
            return __awaiter(this, void 0, void 0, function* () {
                const signer = this.getProvider.getSigner();
                this.current_block = yield this.getProvider.getBlockNumber();
                this.contract = new ethers.Contract(this.contractAddr, VotingABI, signer);
                this.contractTokenAddr = new ethers.Contract(this.contractTokenAddr, ERC20ABI, signer);
            });
        },
        getVoteDetail: function () {
            return __awaiter(this, void 0, void 0, function* () {
                this.isCanVote = yield this.contract.canVote(this.event.id, this.getAddress);
            });
        },
        getAmountHolder: function () {
            return __awaiter(this, void 0, void 0, function* () {
                const decimal = Number(process.env.VUE_APP_MAIN_TOKEN_DECIMAL);
                this.current_amount = yield this.contractTokenAddr.balanceOf(this.getAddress);
                this.current_amount = ethers.utils.formatUnits(this.current_amount.toString(), decimal);
            });
        },
        getAddressVote: function () {
            return __awaiter(this, void 0, void 0, function* () {
                // await this.vote_detail.GetMockData()
                yield this.vote_detail.getData(this.event.id, EventVote.Options_1);
                yield this.vote_detail.getData(this.event.id, EventVote.Options_2);
                this.event.yesAddr = this.vote_detail.getDataFromAPI(EventVote.Options_1);
                this.event.noAddr = this.vote_detail.getDataFromAPI(EventVote.Options_2);
            });
        },
        handleVote(e) {
            return __awaiter(this, void 0, void 0, function* () {
                e.preventDefault();
                let self = this;
                if (this.selected_vote == "") {
                    return this.showErrorChoose();
                }
                let action = EventVote.None;
                if (this.selected_vote == this.Option_1) {
                    action = EventVote.Options_1;
                }
                else if (this.selected_vote == this.Option_2) {
                    action = EventVote.Options_2;
                }
                yield this.contract.vote(this.event.id, action).then(function () {
                    self.showModalConfirm();
                }).catch(function () {
                    self.showErrorVoteResult();
                });
            });
        },
        handleBack() {
            this.$router.push({ name: 'DAO' });
        },
        confirmVote() {
            this.$eventBus.$emit("showNoti", {
                title: toastMessage.vote_success.title,
                description: toastMessage.vote_success.description,
                type: "success",
                duration: 5000,
            });
            this.hideModalConfirm();
            this.handleBack();
        },
        showErrorHolder() {
            this.$eventBus.$emit("showNoti", {
                title: toastMessage.vote_error_holder.title,
                description: toastMessage.vote_error_holder.description,
                type: "error",
                duration: 5000,
            });
        },
        showErrorChoose() {
            this.$eventBus.$emit("showNoti", {
                title: toastMessage.vote_error_choose.title,
                description: toastMessage.vote_error_choose.description,
                type: "error",
                duration: 5000,
            });
        },
        showErrorVoteResult() {
            this.$eventBus.$emit("showNoti", {
                title: toastMessage.vote_error_result.title,
                description: toastMessage.vote_error_result.description,
                type: "error",
                duration: 5000,
            });
        },
        selectedVote(name) {
            this.selected_vote = name;
        },
        selectedListVote(name) {
            this.selected_list_vote = name;
        },
        showModal(name) {
            this.modal_selected_list_vote = name;
            this.is_open = true;
            document.getElementsByTagName('body')[0].style.overflow = "hidden";
        },
        hideModal() {
            this.is_open = false;
            document.getElementsByTagName('body')[0].style.overflow = "";
        },
        showModalConfirm() {
            this.is_open_confirm = true;
            document.getElementsByTagName('body')[0].style.overflow = "hidden";
        },
        hideModalConfirm() {
            this.is_open_confirm = false;
            document.getElementsByTagName('body')[0].style.overflow = "";
        },
    },
    computed: Object.assign(Object.assign({}, mapGetters(MOUDLE_WEB3_NAME, ['getAddress', 'getProvider', 'IsWalletHolder'])), { getTimeEstimate() {
            return this.timerOutput;
        },
        showListVote() {
            let rs = [];
            rs = this.event.yesAddr;
            if (this.selected_list_vote === this.Option_2) {
                rs = this.event.noAddr;
            }
            return rs;
        },
        modalGetListVote() {
            let rs = [];
            if (this.modal_selected_list_vote === this.Option_1) {
                rs = this.event.yesAddr;
            }
            if (this.modal_selected_list_vote === this.Option_2) {
                rs = this.event.noAddr;
            }
            return rs;
        } }),
};
