import { __awaiter } from "tslib";
import { EventVote } from "@/entities/ProposalDetail";
import { getAddressVoted } from "@/api/dao/vote";
import MockEvent from "@/mock/address_vote.json";
import { ethers } from "ethers";
export class VoteAddressInfoUseCase {
    constructor() {
        this.data = new Map;
    }
    getData(pId, _type) {
        return __awaiter(this, void 0, void 0, function* () {
            const results = yield getAddressVoted(pId, _type);
            let walletInfo = [];
            for (let index in results) {
                walletInfo.push({
                    address: results[index].address,
                    value: ethers.utils.formatUnits(results[index].value, process.env.VUE_APP_MAIN_TOKEN_DECIMAL).toString()
                });
            }
            this.data.set(_type, walletInfo);
        });
    }
    GetMockData() {
        return __awaiter(this, void 0, void 0, function* () {
            const walletInfo = MockEvent;
            for (let index in walletInfo) {
                walletInfo[index].value = ethers.utils.formatUnits(walletInfo[index].value, process.env.VUE_APP_MAIN_TOKEN_DECIMAL).toString();
            }
            this.data.set(EventVote.Options_2, walletInfo);
            this.data.set(EventVote.Options_1, walletInfo);
        });
    }
    getDataFromAPI(_type) {
        return this.data.get(_type);
    }
}
