import { __awaiter } from "tslib";
import { getTypeEvent } from "@/api/transaction/get";
import * as defineType from "@/define/event-type";
export class GetTypeEventUseCases {
    constructor() {
        this.data = {};
    }
    getDataFromAPI(event_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const api = yield getTypeEvent(event_id);
            if (api) {
                this.data = api.data;
            }
        });
    }
    getData() {
        return this.data;
    }
    checkTypeEvent(id) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getDataFromAPI(id);
            let result = this.getData();
            if (result == null) {
                return defineType.EVENT_NONE;
            }
            return result.event_type;
        });
    }
}
