import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import SideBar from "../../components/SideBar.vue";
import BreadCrumb from "../../components/BreadCrumb.vue";
import CardListEvent from "../../components/CardListEvent.vue";
export default {
    components: {
        Header,
        Footer,
        SideBar,
        BreadCrumb,
        CardListEvent,
    },
};
