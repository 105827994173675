import { __awaiter } from "tslib";
const BASE_URL = "https://api.roboglobal.info/v1/walletconnect";
export function getNonce(address) {
    return __awaiter(this, void 0, void 0, function* () {
        const reqOpts = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        const response = yield fetch(BASE_URL + "/nonce?address=" + address, reqOpts);
        if (response.status === 200) {
            const data = yield response.json();
            const nonce = data.nonce;
            return [200, nonce];
        }
        return [response.status, ""];
    });
}
export function register(address) {
    return __awaiter(this, void 0, void 0, function* () {
        const reqOpts = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                address: address,
            }),
        };
        const response = yield fetch(BASE_URL + "/signup", reqOpts);
        if (response.status === 201) {
            return null;
        }
        if (response.status === 200) {
            return yield response.json();
        }
        return null;
    });
}
export function performLogin(address, sig) {
    return __awaiter(this, void 0, void 0, function* () {
        const reqOpts = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                address: address,
                signature: sig,
            }),
        };
        const response = yield fetch(BASE_URL + "/login", reqOpts);
        if (response.status === 200) {
            return yield response.json();
        }
        return null;
    });
}
