import { __awaiter } from "tslib";
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
function metamaskWallet() {
    if (typeof window === 'undefined')
        return () => null;
    return () => {
        return {
            label: 'Metamask',
            // @ts-ignore
            getIcon: () => __awaiter(this, void 0, void 0, function* () { return (yield import('./icon')).default; }),
            getInterface: () => __awaiter(this, void 0, void 0, function* () {
                let provider;
                if (window['ethereum']) {
                    provider = window['ethereum'];
                    // @ts-ignore
                    if (!(provider === null || provider === void 0 ? void 0 : provider['isMetaMask']) && !(provider === null || provider === void 0 ? void 0 : provider['isRoboInu'])) {
                        isMobile
                            ? window.open(`https://metamask.app.link/dapp/${process.env.VUE_APP_WEB_URL}`, '_blank')
                            : window.open('https://metamask.io/download/', '_blank');
                        throw new Error('Please Install Metamask Wallet to use this wallet');
                    }
                    return {
                        provider
                    };
                }
                else {
                    isMobile
                        ? window.open(`https://metamask.app.link/dapp/${process.env.VUE_APP_WEB_URL}`, '_blank')
                        : window.open('https://metamask.io/download/', '_blank');
                    throw new Error('Please Install Metamask Wallet to use this wallet');
                }
            })
        };
    };
}
export default metamaskWallet;
