import { __awaiter } from "tslib";
import axios from "axios";
import { ResponseConfigData } from "@/api/config/eve";
const BASE_URL = "https://api.roboglobal.info/v1/eve";
export function getEveConfigVersion() {
    return __awaiter(this, void 0, void 0, function* () {
        return axios.get(BASE_URL + "/dashboard", {
            withCredentials: false,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(ResponseConfigData);
    });
}
