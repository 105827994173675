import { __awaiter } from "tslib";
import { getAirdropHolder, updateAirdropHolder } from "@/api/airdrop/airdrop";
export class AirDropInfoUseCase {
    constructor() {
        this.data = {};
    }
    getData(address, times) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield getAirdropHolder(address, times);
            if (result == undefined) {
                return;
            }
            this.data.address = result.address;
            this.data.amount = result.amount;
            this.data.reward = result.reward;
            this.data.action = result.action;
            this.data.received = result.received;
            this.data.id = result.times;
            this.data.merkleproof = result.merkleProof;
        });
    }
    updateActionHolder(address) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield updateAirdropHolder(address);
            return result != undefined;
        });
    }
    getDataFromAPI() {
        return this.data;
    }
}
