import { __awaiter } from "tslib";
import { getCheckRef } from "@/api/transaction/get";
export class GetCheckRefUseCases {
    constructor() {
        this.data = {};
    }
    getDataFromAPI() {
        return __awaiter(this, void 0, void 0, function* () {
            const api = yield getCheckRef();
            if (api == undefined) {
                return;
            }
            if (api.data == null) {
                this.data.event_id = -1;
                this.data.updated_at = "";
                this.data.name = "";
                this.data.ref_code = "";
                this.data.event_name = "";
                return;
            }
            this.data.event_id = api.data.event_id;
            this.data.updated_at = api.data.updated_at;
            this.data.name = api.data.name;
            this.data.ref_code = api.data.ref_code;
            this.data.event_name = api.data.event_name;
        });
    }
    getData() {
        return this.data;
    }
}
