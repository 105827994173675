import { __awaiter } from "tslib";
import { getEventPostList } from "@/api/transaction/get";
export class GetEventPostListUseCases {
    constructor() {
        this.list = [];
    }
    getDataListHistory(offset, limit, eventid) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataEvent = yield getEventPostList(offset, limit, eventid);
            if (dataEvent == undefined) {
                return;
            }
            this.list = dataEvent.data;
        });
    }
    getDataFromAPI() {
        return this.list;
    }
}
