import { __awaiter } from "tslib";
import { getEventRegList } from "@/api/transaction/get";
export class GetEventRegListUseCases {
    constructor() {
        this.list = [];
    }
    getDataListHistory(offset, limit, eventid) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataEvent = yield getEventRegList(offset, limit, eventid);
            if (dataEvent == undefined) {
                return;
            }
            this.list = dataEvent.data;
        });
    }
    getDataFromAPI() {
        if (this.list.length > 0) {
            return this.list[0];
        }
        else {
            // @ts-ignore
            return;
        }
    }
}
