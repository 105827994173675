import { __awaiter } from "tslib";
import { postIDToGetCodeRef } from "@/api/transaction/post";
export class PostIDToGetCodeRefUseCases {
    constructor() {
        this.data = {};
    }
    getEventID(event_id) {
        this.data.event_id = event_id;
    }
    postData() {
        return __awaiter(this, void 0, void 0, function* () {
            yield postIDToGetCodeRef(this.data);
        });
    }
}
