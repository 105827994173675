export default {
    props: {
        data: {
            type: Object,
        },
        closable: {
            type: Boolean,
            default: true
        },
        showBackground: {
            type: Boolean,
            default: false,
        }
    },
    components: {},
    data() {
        return {};
    },
    methods: {
        hideModal() {
            this.$emit('hideModal');
        },
    },
    created() {
    }
};
