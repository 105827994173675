import { __awaiter } from "tslib";
import Vue from "vue";
import { CONNECT_ACTION, LOGOUT_ACTION, MOUDLE_WEB3_NAME } from "@/store/modules/web3/constant";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    data() {
        return {
            token: null,
            items: [],
        };
    },
    components: {},
    watch: {
        $route() {
            this.getRoute();
        },
        getAddress() {
            if (this.$route.path !== "/") {
                this.$router.push({ path: "/" });
            }
        }
    },
    computed: Object.assign(Object.assign({}, mapGetters(MOUDLE_WEB3_NAME, ['IsLogin', 'getAddress'])), { GetWalletAddress() {
            const connectedAddress = this.getAddress;
            return connectedAddress
                ? connectedAddress.slice(0, 10) +
                    (10 < connectedAddress.length ? "..." : "")
                : "Connect Wallet";
        } }),
    methods: Object.assign(Object.assign({}, mapActions(MOUDLE_WEB3_NAME, [
        CONNECT_ACTION,
        LOGOUT_ACTION,
    ])), { showSideBar() {
            this.$eventBus.$emit("showSideBar");
        },
        getRoute() {
            this.items = [];
            this.$route.matched.forEach((e) => {
                if (e.name === "DAO vote") {
                    e.name = "vote";
                }
                return this.items.push({
                    name: e.name,
                    path: e.path,
                });
            });
        },
        onClickConnect(e) {
            e.preventDefault();
            return this.connect();
        },
        onClickDisconnect(e) {
            e.preventDefault();
            return this.logout();
        } }),
    created() {
        return __awaiter(this, void 0, void 0, function* () {
            this.getRoute();
        });
    },
});
