import { __awaiter } from "tslib";
import Notification from "@/components/Notification.vue";
import ShowInfo from "@/modal/ShowInfo.vue";
import { mapActions, mapGetters } from "vuex";
import { MOUDLE_WEB3_NAME, INIT_WALLET } from "@/store/modules/web3/constant";
export default {
    name: 'App',
    components: {
        ShowInfo,
        Notification,
    },
    computed: Object.assign({}, mapGetters(MOUDLE_WEB3_NAME, ['getProcessing'])),
    methods: Object.assign({}, mapActions(MOUDLE_WEB3_NAME, [INIT_WALLET])),
    created() {
    },
    mounted() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.initWallet();
        });
    }
};
